import React from 'react';
import { hasFlag } from '../core/helpers';
import { useReportInfoContext } from './context/ReportContext';
import { useItemContext } from './context/ItemContext';

type ProjectTypeInputWrapperProps = { children: React.ReactNode };

export default function ProjectTypeInputWrapper({ children }: ProjectTypeInputWrapperProps) {
	const { type: projectType } = useReportInfoContext();
	const { modelType, lists } = useItemContext();
	const { workflows = [] } = lists;

	return !!modelType &&
		projectType &&
		!hasFlag(projectType, modelType) &&
		workflows.length === 0 ? (
		// eslint-disable-next-line react/jsx-indent
		<>Not applicable for this project type</>
	) : (
		<>{children}</>
	);
}
