import React from 'react';
import { useFormikContext } from 'formik';
import { PureCheckboxField } from '../../formaggio';
import { ProjectSubRole, RolesEnum } from '../../auth/enums';
import { useFieldHandler } from '../../core/helpers/stateActionHelpers';
import { useAuthContext } from '../../auth/context';

export default function SubRoleSelector() {
	const { globalOptions = {} } = useAuthContext();

	const formContext = useFormikContext<any>();
	const { role } = formContext.values;
	const { setFieldValue } = formContext;

	const [lead, setLead] = React.useState(false);
	const changeLead = useFieldHandler(setLead);

	const [reviewer, setReviewer] = React.useState(false);
	const changeReviewer = useFieldHandler(setReviewer);

	const [QA, setQA] = React.useState(false);
	const changeQA = useFieldHandler(setQA);

	React.useEffect(() => {
		setFieldValue(
			'projectSubRoles',
			(lead ? ProjectSubRole.Lead : ProjectSubRole.None) +
				(reviewer ? ProjectSubRole.Reviewer : ProjectSubRole.None) +
				(QA ? ProjectSubRole.QA : ProjectSubRole.None),
		);
	}, [lead, reviewer, QA, setFieldValue]);

	if (role !== RolesEnum.Qsa) return null;

	return (
		<>
			<PureCheckboxField label="Lead" name="isLead" onChange={changeLead} checked={lead} />
			<PureCheckboxField
				label="Reviewer"
				name="isReviewer"
				onChange={changeReviewer}
				checked={reviewer}
			/>
			{globalOptions.workflowQAEnabled && (
				<PureCheckboxField label="QA" name="isQA" onChange={changeQA} checked={QA} />
			)}
		</>
	);
}
