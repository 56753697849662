import React from 'react';
import { ReasonBase } from '../core/types/api';
import ItemReasons from './ItemReasons';
import { useAllowedMethods } from '../auth/hooks';

type NotApplicableControlProps = {
	itemId?: string;
	notApplicable?: boolean;
	notApplicableReasons?: ReasonBase[];
};

export default function useNotApplicableControl({
	itemId,
	notApplicable,
	notApplicableReasons,
}: NotApplicableControlProps) {
	const { 'RocItemControllerNew/SetApplicable': canSetApplicable } = useAllowedMethods();

	//Not applicable handling
	const [applicableReasonsState, setApplicableReasons] = React.useState<ReasonBase[] | undefined>(
		notApplicableReasons,
	);
	const [applicableClicked, setApplicableClicked] = React.useState(false);
	React.useEffect(() => {
		setApplicableReasons(notApplicable ? notApplicableReasons : undefined);
	}, [notApplicable, notApplicableReasons]);
	const clickNotApplicable = React.useCallback(() => {
		setApplicableReasons([{}]);
		setApplicableClicked(true);
	}, [setApplicableReasons]);

	return {
		notApplicableReasonsComponent: !!applicableReasonsState && (
			<ItemReasons
				methodName="SetApplicable"
				reasons={applicableReasonsState}
				setReasons={setApplicableReasons}
				canEdit={canSetApplicable}
				applicableClicked={applicableClicked}
				setApplicableClicked={setApplicableClicked}
				itemId={itemId}
			/>
		),
		clickNotApplicable,
	};
}
