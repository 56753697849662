import { jwtDecode } from 'jwt-decode';
import { fromUnixTime } from 'date-fns';
import { AUTH_TOKEN_KEY, roleTitles } from './constants';
import methodsRoles, { ServerMethodNames } from './methodsRoles';
import { RouteType } from '../../features/Portal/pages/Portal';
import { ProjectSubRole, RolesEnum } from './enums';
import { hasFlag } from '../core/helpers';

export function isAuthenticated() {
	return !!localStorage.getItem(AUTH_TOKEN_KEY);
}

export function getTokenTime() {
	const jwt: any = jwtDecode(localStorage.getItem(AUTH_TOKEN_KEY) || '');
	return { started: fromUnixTime(jwt.nbf) || '', expires: fromUnixTime(jwt.exp) || '' };
}

export function getMyMaxRole(roles?: number[]) {
	return Math.min(...(roles || []));
}

export function givenRolesToSelectOptions(roles: number[], isValueRoleName?: boolean) {
	return roles.map((role: number) => ({
		value: isValueRoleName ? RolesEnum[role] : role,
		title: roleTitles[role],
	}));
}

export function getAvailableRoles(roles: number[], myGreatestRole?: number) {
	if (!myGreatestRole) return [];

	return roles.filter((value) => value >= myGreatestRole);
}

function filteredAllowedMethods(methods: ServerMethodNames[], userRoles?: number[]) {
	if (!userRoles) return [];

	return methods.reduce<any>((memo, methodName) => {
		const methodRoles = methodsRoles[methodName];

		const isMethodAllowed =
			methodRoles?.some((methodRole) => userRoles.indexOf(methodRole) >= 0) ||
			methodRoles?.length === 0;

		if (!isMethodAllowed) return memo;

		return [...memo, methodName];
	}, []);
}

export function reduceRoleRoutes(memo: object[], currentRoute: RouteType, userRoles?: number[]) {
	const canUse = currentRoute.usedMethods;
	if (!userRoles || canUse.length === 0) return memo;

	const allowedMethods = filteredAllowedMethods(canUse, userRoles);

	if (allowedMethods.length === 0) return memo;

	const finalRoute = currentRoute;
	if (currentRoute.path === '/portal/companies') {
		const title =
			getMyMaxRole(userRoles) >= RolesEnum.ClientAdministrator ? 'Company' : 'Companies';
		finalRoute.title = title;
		if (finalRoute.sidebarItem) {
			finalRoute.sidebarItem.label = title;
		}
	}

	return [...memo, { ...finalRoute, allowedMethods }];
}

/**
 * Takes list 'roles' and removes 'otherRoles' from it
 * @param roles
 * @param otherRoles
 * @param isOppositeFilter
 */
export function filterRolesFromOtherRoles(
	roles: number[],
	otherRoles: number[],
	isOppositeFilter?: boolean,
): number[] {
	const originalRoles = [...roles];
	return originalRoles.filter((currentRole: number) => {
		const hasRole = otherRoles.indexOf(currentRole) >= 0;
		return isOppositeFilter ? hasRole : !hasRole;
	});
}

export function isClientRole(roles: number[] = []) {
	return (
		roles.indexOf(RolesEnum.Client) >= 0 ||
		roles.indexOf(RolesEnum.ExecutiveClientRepresentative) >= 0
	);
}

export function getProjectSubRoleNames(subRoles = 0) {
	const result = [];

	if (hasFlag(subRoles, ProjectSubRole.Lead)) result.push('Lead');
	if (hasFlag(subRoles, ProjectSubRole.Reviewer)) result.push('Reviewer');
	if (hasFlag(subRoles, ProjectSubRole.QA)) result.push('QA');

	return result.join(', ');
}
