import React from 'react';
import { css, cx } from '@emotion/css/macro';
import { Box } from '@mui/material';
import UserInput from './UserInput';
import { FetchAPI } from '../core/actions/fetchAPI';
import { RocDataType } from './enums';
import { toSelectOptions } from '../formaggio';
import { phrases } from '../core/constants/phrases';
import {
	filterSaqPaymentChannels,
	hasMultipleResponses,
	isFedRampVersion,
	isScopeVersion,
} from './helpers';
import { Channel } from '../core/types/api';
import { useReportAccess } from '../auth/hooks';
import { extractPaymentChannelIds } from './rocItems/TableCell';
import { useItemContext } from './context/ItemContext';
import { ItemStatus } from '../project/enums';
import { RocItem } from './types';
import UserControls from './UserControls';
import { hasFlag } from '../core/helpers';
import OptionalFilesList from '../evidence/components/OptionalFilesList';

export const ALL_PAYMENT_CHANNELS = 'all';

export const allPaymentChannelsOption = {
	value: ALL_PAYMENT_CHANNELS,
	title: phrases.allChannels,
};

export function paymentChannelsToSelect(channels: any = []) {
	return toSelectOptions(channels, 'id', 'name');
}

function shouldShowNewResponse({
	dataState = [],
	isAQSA,
	isReadOnly,
	status,
	multipleResponses,
	useDifferentValuesPerPaymentChannels,
	dataPaymentChannels,
	availableChannels = [],
	rocDataType,
	writePermissionMask = 0,
	role = 0,
	version,
}: any) {
	const hasNoData = dataState.length === 0;

	if (isFedRampVersion(version)) return hasNoData;

	if (
		isReadOnly ||
		status === ItemStatus.Approved ||
		(!isScopeVersion(version) && availableChannels.length < 1) ||
		(!isAQSA && !!role && !writePermissionMask && !hasFlag(writePermissionMask, role))
	)
		return false;

	if (hasMultipleResponses(multipleResponses, rocDataType)) return true;
	if (!useDifferentValuesPerPaymentChannels) return hasNoData;
	if (dataPaymentChannels.indexOf(undefined) >= 0) return false;

	//check if some channels are not filled
	return !availableChannels.every(
		({ id: currPCId }: any) => dataPaymentChannels.indexOf(currPCId) >= 0,
	);
}

function renderData({
	dataState,
	emptyValue = {},
	InputElement,
	PresentationElement,
	inputClassName,
	paymentChannelsVarious,
	useDifferentValuesPerPaymentChannels,
	className,
	getData = ({ value }: any) => ({ value }),
	rocDataType,
	...props
}: any) {
	const result = [...dataState];
	const hasNewResponse = shouldShowNewResponse({
		dataState,
		useDifferentValuesPerPaymentChannels,
		rocDataType,
		...props,
	});

	if (hasNewResponse) result.push({ isNewMode: true, initialValue: getData(emptyValue) });

	return result.map(({ value, id, ...rest }: any, idx: number) => (
		<UserInput
			responseId={id}
			idx={idx}
			initialValue={getData({ value, ...rest })}
			autoPCId={paymentChannelsVarious.autoPCId}
			manyPaymentChannels={paymentChannelsVarious.manyPaymentChannels}
			inputElement={InputElement}
			presentationElement={PresentationElement}
			className={cx(className, inputClassName)}
			useDifferentValuesPerPaymentChannels={useDifferentValuesPerPaymentChannels}
			paymentChannelsSelectOptions={
				id ? paymentChannelsVarious.allSelect : paymentChannelsVarious.filteredSelect
			}
			isReadOnly={rocDataType === RocDataType.SummaryOfFindingsState}
			showDivider={!!id && hasNewResponse}
			emptyValue={emptyValue}
			rocDataType={rocDataType}
			{...props}
			{...rest}
			key={idx}
		/>
	));
}

const rootClass = css`
	border-radius: 5px;
	position: relative;
	display: flex;
	flex-direction: column;
`;

type UserDataProps = {
	retrievedValue: unknown;
	emptyValue: unknown;
	inputClassName: string;
	fetchAPI: FetchAPI;
	refresh: () => void;
	modelId: string;
	inputElement: React.FC;
	presentationElement: React.FC;
	additional?: Record<string, unknown>;
	disclosureValueToSend?: string;
	dataType: RocDataType;
	saveTimeout?: number;
	writePermissionMask?: number;
	isReadOnly?: boolean;
	manualSave?: boolean;
	validation?: (arg: any) => string | undefined; //Return error in result string, undefined if no errors
	rocDataType: RocDataType;
	itemDescription?: string;
} & RocItem;

function UserData({
	emptyValue,
	retrievedValue,
	data = [],
	inputElement: InputElement,
	presentationElement: PresentationElement,
	rocDataType,
	multipleResponses,
	itemDescription,
	...rest
}: UserDataProps) {
	const { paymentChannels, role, isAQSA } = useReportAccess();

	//Data state
	const [dataState, setDataState] = React.useState<any[]>(data);

	const dataPaymentChannels = React.useMemo(() => extractPaymentChannelIds(dataState), [dataState]);

	React.useEffect(() => {
		setDataState(data);
	}, [data, setDataState]);

	//SAQ + Payment Channels
	const { status, useDifferentValuesPerPaymentChannels, lists } = useItemContext();
	const availableChannels = React.useMemo(
		() =>
			filterSaqPaymentChannels(
				paymentChannels,
				[...lists.applicableChannelIds, ...dataPaymentChannels],
				useDifferentValuesPerPaymentChannels,
			),
		[
			paymentChannels,
			useDifferentValuesPerPaymentChannels,
			lists.applicableChannelIds,
			dataPaymentChannels,
		],
	);

	const paymentChannelsVarious = React.useMemo(() => {
		const hasData = dataPaymentChannels.length > 0;
		const canHaveManyResponsesPerChannel = hasMultipleResponses(multipleResponses, rocDataType);

		if (!availableChannels)
			return {
				filteredSelect:
					!canHaveManyResponsesPerChannel && hasData ? [] : [allPaymentChannelsOption],
				allSelect: [allPaymentChannelsOption],
				autoPCId: null,
			};

		const filteredPaymentChannels = canHaveManyResponsesPerChannel
			? availableChannels
			: availableChannels.filter(({ id: PCId }: Channel) => dataPaymentChannels.indexOf(PCId) < 0);

		let autoPCId = null;
		if (availableChannels) {
			if (availableChannels.length === 1) autoPCId = availableChannels[0].id;
			if (availableChannels.length === 0) autoPCId = ALL_PAYMENT_CHANNELS;
		}

		const allSelect = [allPaymentChannelsOption, ...paymentChannelsToSelect(availableChannels)];

		return {
			filteredSelect: [
				...(!hasData || multipleResponses ? [allPaymentChannelsOption] : []),
				...paymentChannelsToSelect(filteredPaymentChannels || []),
			],
			allSelect,
			autoPCId,
			manyPaymentChannels: availableChannels.length > 1,
		};
	}, [dataPaymentChannels, multipleResponses, availableChannels, rocDataType]);

	const render = renderData({
		dataState,
		setDataState,
		InputElement,
		PresentationElement,
		dataPaymentChannels,
		emptyValue,
		isAQSA,
		paymentChannelsVarious,
		availableChannels,
		retrievedValue,
		useDifferentValuesPerPaymentChannels,
		rocDataType,
		multipleResponses,
		...rest,
		status,
		role,
	});

	return (
		<div className={rootClass}>
			{itemDescription && (
				<Box fontSize="13px" my="2px">
					{itemDescription}
				</Box>
			)}
			<UserControls availableChannelOptions={paymentChannelsVarious.filteredSelect} {...rest} />
			<OptionalFilesList />
			{render}
		</div>
	);
}

export default UserData;
