import React from 'react';
import ChannelReasonList from '../project/components/paymentChannels/ChannelReasonList';
import { useItemContext } from './context/ItemContext';

type SummaryNAReasonsProps = { children: React.ReactNode; notApplicableReasons: any[] };

export default function SummaryNAReasons({
	children,
	notApplicableReasons,
}: SummaryNAReasonsProps) {
	const { displayReasonsBox } = useItemContext();
	return (
		<>
			{children}
			{displayReasonsBox && <ChannelReasonList notApplicableReasons={notApplicableReasons} />}
		</>
	);
}
