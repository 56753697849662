import React from 'react';
import { css, cx } from '@emotion/css/macro';
import { Warning } from '@mui/icons-material';
import { error, darkgrey } from '../Core/stylesheets/colors';
import { fontSize } from '../Core/stylesheets/text';
import { spacing } from '../Core/stylesheets/spacing';
import { fade } from '../Core/stylesheets/keyframes';

const labelCss = css`
	display: block;
	font-size: ${fontSize.text};
	margin-bottom: ${spacing.small};
`;

const requiredAsteriskCss = css`
	color: ${error};
`;

const errorTextMixin = css`
	color: ${error};
`;

const errorMessageCss = css`
	${errorTextMixin};
	font-size: ${fontSize.helperText};
	animation: ${fade} 0.2s;
	margin-top: ${spacing.small};
`;

const errorIconCss = css`
	margin-right: 0.25rem;
`;

const hintCss = css`
	margin: 0;
	margin-top: ${spacing.small};
	color: ${darkgrey};
	font-size: ${fontSize.helperText};
`;

export type InputContainerProps = {
	id: string;
	children: (inputProps: {
		id: string;
		disabled?: boolean;
		required?: boolean;
		hasError?: boolean;
	}) => React.ReactNode;
	label?: React.ReactNode;
	disabled?: boolean;
	required?: boolean;
	hasError?: boolean;
	errorMessage?: React.ReactNode;
	hint?: React.ReactNode;
};

export type WithInputContainer<T> = Omit<InputContainerProps, 'children'> & Omit<T, 'id'>;

export default function InputContainer({
	id,
	children,
	label,
	disabled,
	required,
	hasError,
	errorMessage,
	hint,
}: InputContainerProps) {
	return (
		<>
			{label && (
				<label htmlFor={id} className={cx(labelCss, hasError && errorTextMixin)}>
					{required && <span className={requiredAsteriskCss}>*&nbsp;</span>}
					{label}
				</label>
			)}
			{children({
				id,
				disabled,
				required,
				hasError,
			})}
			{hasError && errorMessage && (
				<div className={errorMessageCss}>
					<Warning className={errorIconCss} />
					{errorMessage}
				</div>
			)}
			{hint && <div className={hintCss}>{hint}</div>}
		</>
	);
}
