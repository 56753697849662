import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import { Chip, IconButton, Table, TableBody, TableCell, TableRow } from '@mui/material';
import { Cancel, DeleteForever, Edit, Link, Save } from '@mui/icons-material';
import { useAPI, useToggle } from '../../core/hooks';
import { BasicForm, PureTextField, TextField } from '../../formaggio';
import { searchTextFilter } from '../../../shared/helpers';
import { useFieldHandler } from '../../core/helpers/stateActionHelpers';
import { RouterLink } from '../../core/components';
import { idsToProjectPath } from '../../project/helpers';

function RenderMatrixRequirement({ contentsInfo, customizedApproachObjective }: any) {
	if (!customizedApproachObjective) return <>Objective not found</>;

	return (
		<p>
			<b>{contentsInfo?.numbering}</b>: {customizedApproachObjective}
		</p>
	);
}

function RenderMatrixRow({
	projectId,
	itemId,
	requirementApproach,
	controlsMatrixIds = [],
	selectControlsMatrixId,
	removeMatrix,
	refetch,
}: any) {
	const isSelected = React.useMemo(
		() => controlsMatrixIds.indexOf(itemId) >= 0,
		[controlsMatrixIds, itemId],
	);
	const select = React.useCallback(() => {
		selectControlsMatrixId(itemId);
	}, [itemId, selectControlsMatrixId]);
	const remove = React.useCallback(() => removeMatrix(itemId), [itemId, removeMatrix]);
	const render = React.useMemo(() => {
		const list = (requirementApproach?.linkedRequirements || []).map((req, key) => (
			<RenderMatrixRequirement key={key} {...req} />
		));

		return list.length > 0 ? (
			<>
				<p>Linked requirements:</p>
				{list}
			</>
		) : (
			<>No requirements linked</>
		);
	}, [requirementApproach?.linkedRequirements]);

	// Edit controls matrix
	const { isOn, toggle, toggleOff } = useToggle();

	return (
		<TableRow>
			<TableCell width={300}>
				<Box marginBottom="8px">
					{isOn ? (
						<BasicForm
							fetchConfig={{
								query: `Approaches/UpdateControlsMatrix/${projectId}/${itemId}`,
								method: 'PUT',
								onSuccess: () => {
									toggleOff();
									refetch();
								},
							}}
							initialValues={{ name: requirementApproach?.customizedControlName }}
						>
							<TextField name="name" multiline />
							<IconButton type="submit">
								<Save />
							</IconButton>
						</BasicForm>
					) : (
						<RouterLink to={idsToProjectPath(projectId, itemId)}>
							{requirementApproach?.customizedControlName}
						</RouterLink>
					)}
					<IconButton onClick={toggle}>{isOn ? <Cancel /> : <Edit />}</IconButton>
				</Box>
				{isSelected && <Chip color="secondary" label="Linked" avatar={<Link />} />}
			</TableCell>
			<TableCell width={600}>{render}</TableCell>
			<TableCell>
				<ButtonGroup size="small">
					<Button onClick={select} color={isSelected ? 'secondary' : 'primary'}>
						{isSelected ? 'Unlink' : 'Select'}
					</Button>
					<Button startIcon={<DeleteForever />} onClick={remove}>
						Remove
					</Button>
				</ButtonGroup>
			</TableCell>
		</TableRow>
	);
}

type ControlsMatrixListProps = {
	projectId?: string;
	controlsMatrixIds: string[];
	selectControlsMatrixId: (arg: string) => void;
	isApproachUsed?: boolean;
};

export default function ControlsMatrixList({
	projectId,
	controlsMatrixIds,
	selectControlsMatrixId,
}: ControlsMatrixListProps) {
	const [filter, setFilter] = React.useState('');
	const handle = useFieldHandler(setFilter);

	const {
		state,
		fetchAPI,
		initialFetch: refetch,
	} = useAPI({
		props: { query: `Approaches/ListControlMatrices/${projectId}` },
		autoLoad: true,
	});

	const addNew = React.useCallback(
		() =>
			fetchAPI({
				query: `Approaches/AddControlsMatrix/${projectId}`,
				method: 'POST',
				onSuccess: () => {
					setFilter('');
					refetch();
				},
				params: { name: filter },
			}),
		[fetchAPI, projectId, refetch, filter],
	);

	const remove = React.useCallback(
		(id: string) =>
			fetchAPI({
				query: `Approaches/DeleteControlsMatrix/${projectId}/${id}`,
				method: 'DELETE',
				onSuccess: () => {
					setFilter('');
					refetch();
				},
				confirmation: {
					title: `Are you sure you want to completely remove this control from the project?`,
					message: 'Approach links and data will be removed',
				},
			}),
		[fetchAPI, projectId, refetch],
	);

	const render = React.useMemo(
		() =>
			(state?.autodata?.data || [])
				.filter(({ requirementApproach = {} }: any) =>
					searchTextFilter(requirementApproach.customizedControlName, filter),
				)
				.map((item, key) => (
					<RenderMatrixRow
						key={key}
						{...item}
						controlsMatrixIds={controlsMatrixIds}
						selectControlsMatrixId={selectControlsMatrixId}
						removeMatrix={remove}
						projectId={projectId}
						refetch={refetch}
					/>
				)),
		[
			state?.autodata?.data,
			filter,
			controlsMatrixIds,
			selectControlsMatrixId,
			remove,
			projectId,
			refetch,
		],
	);

	return (
		<>
			<Box display="flex" alignItems="center">
				<PureTextField
					onChange={handle}
					value={filter}
					label="Search / Create"
					helperText="Enter query or unique name (min 4 chars)"
				/>
				<Button onClick={addNew} disabled={render.length > 0 || filter.length < 4}>
					Create custom control
				</Button>
			</Box>
			<Table size="small">
				<TableBody>{render}</TableBody>
			</Table>
		</>
	);
}
