import React from 'react';
import { css } from '@emotion/css/macro';
import { get, set } from 'lodash';
import Tooltip from '@material-ui/core/Tooltip';
import { PureTextField, PureSelectField, PureCheckboxField } from '../../../formaggio';
import { rocItemTypesSelect } from '../../constants';
import { Dropdown, FieldSet } from '../../../core/components';
import ItemDependentFields from './ItemDependentFields';
import ItemControlLayout from '../../../layout/components/ItemControlLayout';
import { toStringPath } from '../helpers';
import { NodeType, RocItemType } from '../../enums';
import StyleAndAttributesField from './StyleAndAttributesField';
import { ResponsibilityAreaSelector } from '../../../contact/components/ResponsibilityAreaSelector';
import { flagSumToArray } from '../../../core/helpers';

export const fieldClass = css`
	margin-right: 0.5rem;
`;

const horizontalClass = css`
	display: flex;
	& > div {
		margin-right: 0.5rem;
	}
`;

const nodeTypeDescriptions = Object.values(NodeType).reduce(
	(memo, curr, idx, objValues) =>
		typeof curr === 'number'
			? memo
			: [
					...memo,
					// eslint-disable-next-line react/jsx-indent
					<p>
						{curr} = {objValues[objValues.length / 2 + idx]}
					</p>,
				],
	[],
);

export type ItemGeneralFieldsProps = {
	isEditMode?: boolean;
	DTO: object;
	setDTO: (arg: any) => void;
	submitButton?: React.ReactNode;
	path: string[];
};

export default function ItemGeneralFields({
	isEditMode,
	DTO,
	setDTO,
	submitButton,
	path,
}: ItemGeneralFieldsProps) {
	const nestedDTO = React.useMemo(
		() => (path.length > 0 ? get(DTO, toStringPath(path), {}) : DTO),
		[DTO, path],
	);

	const itemId = React.useMemo(() => get(nestedDTO, 'itemId', ''), [nestedDTO]);

	const onChange = React.useCallback(
		({ name, value }: any) => {
			setDTO({ ...set(DTO, toStringPath([...path, name]), value) });
		},
		[setDTO, DTO, path],
	);

	const onChangeField = React.useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const { name, type, checked, value } = e.target;
			if (name === 'type') {
				if (value === RocItemType.OneMultipleRow.toString()) {
					onChange({ name: 'firstColumn.type', value: RocItemType.TextBlock.toString() });
				}
			}
			onChange({ name, value: type === 'checkbox' ? checked : value });
		},
		[onChange],
	);

	const area = React.useMemo(() => get(nestedDTO, 'responsibilityArea', 0), [nestedDTO]);

	const setArea = React.useCallback(
		(value: number) => onChange({ name: 'responsibilityArea', value }),
		[onChange],
	);

	return (
		<ItemControlLayout
			aside={submitButton}
			toolbar={
				<>
					<PureSelectField
						disabled={isEditMode}
						label="Type"
						name="type"
						options={rocItemTypesSelect}
						className={fieldClass}
						customChangeHandler={onChangeField}
						value={get(nestedDTO, 'type')}
					/>
					<Tooltip
						title={
							<>
								{nodeTypeDescriptions}
								<p>This one is: {flagSumToArray(get(nestedDTO, 'nodeType', 0)).join(', ')}</p>
								<small>
									Cheatsheet for complex items:
									<br />
									Row = 17
									<br />
									Column = 33 (+2 if copyable, e.g. text field)
									<br />
									Array = 259
									<br />
									Row in array = 83
									<br />
									Column in array = 99 (always)
								</small>
							</>
						}
					>
						<div>
							<PureTextField
								name="nodeType"
								label="Node Type"
								onChange={onChangeField}
								value={get(nestedDTO, 'nodeType')}
								className={fieldClass}
							/>
						</div>
					</Tooltip>
					<PureTextField
						name="subType"
						label="Sub-type"
						onChange={onChangeField}
						value={get(nestedDTO, 'subType')}
						className={fieldClass}
					/>
					<PureTextField
						onChange={onChangeField}
						value={get(nestedDTO, 'order')}
						name="order"
						label="Order"
						className={fieldClass}
					/>
					<PureTextField
						name="variableName"
						label="Variable Name"
						onChange={onChangeField}
						value={get(nestedDTO, 'variableName')}
						className={fieldClass}
					/>
					<PureTextField
						name="rocDataType"
						label="RocDataType"
						onChange={onChangeField}
						value={get(nestedDTO, 'rocDataType')}
						className={fieldClass}
					/>
					<PureCheckboxField
						onChange={onChangeField}
						checked={get(nestedDTO, 'isReadOnly')}
						name="isReadOnly"
						label="Readonly"
						className={fieldClass}
					/>
				</>
			}
			footer={
				itemId && (
					<div>
						<small>#{itemId}</small>
					</div>
				)
			}
		>
			<ItemDependentFields
				type={parseInt(get(nestedDTO, 'type', ''), 10)}
				onChangeField={onChangeField}
				onChange={onChange}
				nestedDTO={nestedDTO}
				DTO={DTO}
				setDTO={setDTO}
				path={path}
			/>
			<StyleAndAttributesField
				onChange={onChange}
				name="style"
				storage={nestedDTO}
				title="Style &amp; Attributes"
			/>
			<Dropdown header="More" hideContents>
				<div className={horizontalClass}>
					<PureTextField
						onChange={onChangeField}
						name="readPermissionMask"
						label="Read"
						value={get(nestedDTO, 'readPermissionMask')}
						helperText="permission mask"
					/>
					<PureTextField
						onChange={onChangeField}
						value={get(nestedDTO, 'writePermissionMask')}
						name="writePermissionMask"
						label="Write"
						helperText="permission mask"
					/>
				</div>
				<FieldSet legend="Misc">
					<ResponsibilityAreaSelector
						currentAreas={area}
						initialAreas={area}
						setActualAreas={setArea}
						style={{ margin: '0 1rem 1rem 0' }}
						noPrimaryContact
					/>
				</FieldSet>
				<FieldSet legend="Contents Info">
					<PureTextField
						onChange={onChangeField}
						value={get(nestedDTO, 'contentsInfo.header')}
						name="contentsInfo.header"
						label="Header"
						fullWidth
					/>
					<PureTextField
						onChange={onChangeField}
						value={get(nestedDTO, 'contentsInfo.level')}
						name="contentsInfo.level"
						label="Level"
					/>
					<PureTextField
						onChange={onChangeField}
						value={get(nestedDTO, 'contentsInfo.numbering')}
						name="contentsInfo.numbering"
						label="Numbering"
					/>
				</FieldSet>
			</Dropdown>
		</ItemControlLayout>
	);
}
