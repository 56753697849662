import React from 'react';
import { css } from '@emotion/css/macro';
import InputContainer, { WithInputContainer } from '../InputContainer';
import CheckboxInput from './CheckboxInput';
import { spacing } from '../Core/stylesheets/spacing';

const optionCss = css`
	margin-bottom: ${spacing.small};
`;

export type CheckboxOption = {
	value: string;
	label: React.ReactNode;
	disabled?: boolean;
};

export type CheckboxGroupProps = WithInputContainer<{
	options: CheckboxOption[];
	value?: string[];
	onChange?: (nextValue: string[]) => void;
	className?: string;
}>;

export default function CheckboxGroup({
	options,
	className,
	id,
	value,
	onChange,
	...rest
}: CheckboxGroupProps) {
	const [innerValue, setInnerValue] = React.useState<string[]>([]);

	const handleOptionChange = React.useCallback<React.ChangeEventHandler<HTMLInputElement>>(
		(e) => {
			const { value: optionValue, checked } = e.target;

			const prevState = value === undefined ? innerValue : value;
			const nextState = checked
				? prevState.concat([optionValue])
				: prevState.filter((option) => option !== optionValue);

			if (value === undefined) {
				setInnerValue(nextState);
				if (onChange) {
					onChange(nextState);
				}
			} else if (onChange) {
				onChange(nextState);
			} else {
				// eslint-disable-next-line no-console
				console.error(
					'value is not undefined but onChange callback is not passed to CheckboxGroup',
				);
			}
		},
		[setInnerValue, innerValue, value, onChange],
	);

	const renderOption = React.useCallback(
		({ value: optionValue, label: optionLabel, disabled }: CheckboxOption) => {
			const optionId = `${id}__${optionValue}`;
			const checked =
				value === undefined
					? innerValue.includes(optionValue)
					: (value || []).includes(optionValue);

			return (
				<CheckboxInput
					key={optionId}
					id={optionId}
					value={optionValue}
					label={optionLabel}
					className={optionCss}
					onChange={disabled ? () => undefined : handleOptionChange}
					checked={checked}
					disabled={disabled}
				/>
			);
		},
		[id, handleOptionChange, innerValue, value],
	);

	return (
		<div className={className} id={id}>
			<InputContainer id={id} {...rest}>
				{() => <>{options.map(renderOption)}</>}
			</InputContainer>
		</div>
	);
}
