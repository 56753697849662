import React from 'react';
import { css } from '@emotion/css/macro';
import { Button, FieldSet, ThreeStateThumbButton } from '../../core/components';
import { useReportAccess } from '../../auth/hooks';
import { useItemContext } from '../../rocTemplates/context/ItemContext';
import { ItemStatus } from '../../project/enums';
import { useAPI, useToggle } from '../../core/hooks';
import { useReportContext } from '../../rocTemplates/context/ReportContext';
import MaterialDialog from '../../flyout/components/MaterialDialog';
import { PureTextField } from '../../formaggio';
import { useFieldHandler } from '../../core/helpers/stateActionHelpers';
import { useAuthContext } from '../../auth/context';
import { WorkflowRole } from '../../core/types/api';

function getApprovedDecision(workflowRole: WorkflowRole, isQAEnabled: boolean): ItemStatus {
	if (workflowRole === WorkflowRole.QA) return ItemStatus.Approved;

	//If peer reviewer
	return isQAEnabled ? ItemStatus.ReadyForQA : ItemStatus.Approved;
}

const controlsClass = css`
	display: flex;
`;

const declinedClass = css`
	margin-top: 8px;
`;

export default function WorkflowPeerReviewQuickApprove() {
	const { globalOptions = {} } = useAuthContext();
	const { workflowRole } = useReportAccess();
	const { projectId } = useReportContext();
	const { status, itemId } = useItemContext();
	const { initialFetch } = useAPI({
		props: { query: 'Workflow/Create', method: 'POST', useFormData: true },
	});
	const [decision, setDecision] = React.useState<boolean | undefined>();

	const decide = React.useCallback(
		(decision: boolean, message?: string) => {
			initialFetch({
				params: {
					projectId,
					itemId,
					status: decision
						? getApprovedDecision(workflowRole, globalOptions.workflowQAEnabled || false)
						: ItemStatus.ChangesRequested,
					message,
					visibleToCustomers: false,
				},
				onSuccess: () => setDecision(decision),
			});
		},
		[initialFetch, projectId, itemId, workflowRole, globalOptions.workflowQAEnabled, setDecision],
	);

	const { isOn, toggleOn, toggleOff } = useToggle();
	const [reason, setReason] = React.useState('');
	const handleReason = useFieldHandler(setReason);
	const decline = React.useCallback(() => {
		decide(false, reason);
		toggleOff();
	}, [decide, reason, toggleOff]);
	const cancelDecision = React.useCallback(() => {
		setDecision(undefined);
		setReason('');
	}, [setDecision, setReason]);

	const onClick = React.useCallback(
		(decision: boolean) => (decision ? decide(true) : toggleOn()),
		[decide, toggleOn],
	);

	if (
		(workflowRole === WorkflowRole.PeerReviewer &&
			(status === ItemStatus.ReadyForPeerReview ||
				(!globalOptions.workflowQAEnabled && status === ItemStatus.ReadyForQA))) ||
		(workflowRole === WorkflowRole.QA && status === ItemStatus.ReadyForQA)
	)
		return (
			<>
				<FieldSet
					legend={
						workflowRole === WorkflowRole.PeerReviewer ? 'Peer Review Decision' : 'QA Decision'
					}
				>
					<div className={controlsClass}>
						<ThreeStateThumbButton
							disabled={decision !== undefined}
							onClick={onClick}
							value={decision}
						/>
						{decision !== undefined && (
							<Button size="small" onClick={cancelDecision}>
								Cancel
							</Button>
						)}
					</div>
					{!!reason && <div className={declinedClass}>Declined: {reason}</div>}
				</FieldSet>
				<MaterialDialog
					isOpen={isOn}
					actions={
						<>
							<Button color="primary" onClick={decline}>
								Submit
							</Button>
							<Button color="secondary" onClick={toggleOff}>
								Cancel
							</Button>
						</>
					}
					onClose={toggleOff}
				>
					Enter reason on why this item was declined
					<PureTextField
						fullWidth
						multiline
						variant="outlined"
						value={reason}
						onChange={handleReason}
					/>
				</MaterialDialog>
			</>
		);

	return null;
}
