import React from 'react';
import { css } from '@emotion/css/macro';
import { PureSelectField, PureTextField } from '../formaggio';
import { useMultiFieldHandler } from '../core/helpers/stateActionHelpers';

const filterClass = css`
	display: flex;
`;

export default function useAttachmentFilter(
	initialFilter: any = {},
	sortByOptions?: { title: string; value: number }[],
	withRequirement = false,
) {
	const [filter, setFilter] = React.useState({
		filter: '',
		error: '',
		...initialFilter,
	});
	const changeFilter = useMultiFieldHandler(setFilter);

	return {
		filter,
		filterComponent: React.useMemo(
			() => (
				<div className={filterClass}>
					<PureTextField
						onChange={changeFilter}
						value={filter.filter}
						fullWidth
						name="filter"
						label="Search"
						errorMessage={filter.error}
					/>
					{withRequirement && (
						<PureTextField
							onChange={changeFilter}
							value={filter.requirement}
							label="Requirement"
							name="requirement"
							helperText="R1-R12, A1-A2"
							noEmpty
						/>
					)}
					{sortByOptions && (
						<PureSelectField
							onChange={changeFilter}
							value={filter.sortBy}
							label="Sort by"
							name="sortBy"
							options={sortByOptions}
							noEmpty
						/>
					)}
				</div>
			),
			[
				changeFilter,
				filter.error,
				filter.filter,
				filter.requirement,
				filter.sortBy,
				sortByOptions,
				withRequirement,
			],
		),
	};
}
