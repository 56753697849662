import { css } from '@emotion/css/macro';

export const fontSize = {
	text: '13px',
	helperText: '12px',
};

export const wordBreak = css`
	word-break: break-all;
	word-wrap: break-word;
	hyphens: auto;
`;
