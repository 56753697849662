import React from 'react';
import { AvailablePCsType, RocItem } from '../types';
import { ItemStatus } from '../../project/enums';
import { NodeType, RocItemType } from '../enums';
import { getLists, isInputItem } from '../helpers';
import { useReportAccess } from '../../auth/hooks';
import { useReportContext } from './ReportContext';
import { hasFlag } from '../../core/helpers';
import { rocItemInputs } from '../constants';
import { HelpMessage, InterviewModel, Workflow } from '../../../graphql/typescript';
import { CustomSaqStatus } from '../../customSaq/enums';
import { extractPaymentChannelIds } from '../rocItems/TableCell';

type ItemContextType = Partial<RocItem> & {
	lists: {
		workflows: Workflow[];
		interviews: InterviewModel[];
		hints: HelpMessage[];
		allChannelStates: AvailablePCsType;
		applicableChannelIds: string[];
	};
	isItemApproved?: boolean;
	overrideSaqPC?: (arg?: string) => void;
	isOverriding?: boolean;
	isItemAvailable?: boolean;
	displayReasonsBox?: boolean;
	itemProperties: {
		isProjectItem: boolean;
		isProjectInput: boolean;
		isSummary: boolean;
		isAttendeesList: boolean;
	};
};

const itemContextInitial: ItemContextType = {
	lists: {
		workflows: [],
		interviews: [],
		hints: [],
		allChannelStates: [],
		applicableChannelIds: [],
	},
	overrideSaqPC: () => undefined,
	itemProperties: {
		isAttendeesList: false,
		isProjectInput: false,
		isProjectItem: false,
		isSummary: false,
	},
};

const ItemContext = React.createContext<ItemContextType>(itemContextInitial);

//General item context

type ItemContextWrapperProps = Partial<
	Omit<ItemContextType, 'children'> & { children: Partial<RocItem> }
>;

function ItemContextWrapper({
	itemId,
	useDifferentValuesPerPaymentChannels = false,
	status,
	rocWarnings,
	hiddenWarnings,
	saq,
	children,
	type,
	isAutoGenerated,
}: ItemContextWrapperProps) {
	//Controls context to avoid passing these through all replicator props
	const [context, setContext] = React.useState<ItemContextType>(itemContextInitial);

	const { workflows = [] } = useReportContext();

	React.useEffect(() => {
		setContext({
			...itemContextInitial,
			itemId,
			useDifferentValuesPerPaymentChannels,
			status,
			isItemApproved: status === ItemStatus.Approved,
			saq,
			lists: {
				workflows: workflows.filter(({ itemId: iid }) => itemId === iid) || [],
				interviews: [],
				hints: [],
				allChannelStates: [],
				applicableChannelIds: [],
			},
			isItemAvailable: true,
			isAutoGenerated,
		});
	}, [
		setContext,
		useDifferentValuesPerPaymentChannels,
		status,
		rocWarnings,
		hiddenWarnings,
		saq,
		type,
		isAutoGenerated,
		workflows,
		itemId,
	]);

	return <ItemContext.Provider value={context}>{children as any}</ItemContext.Provider>;
}

//Input item context

function InputItemContextWrapper({
	itemId,
	type,
	nodeType,
	saq,
	modelType,
	data = [],
	summaryId,
	useDifferentValuesPerPaymentChannels,
	status,
	rocWarnings,
	hiddenWarnings,
	children,
	rocDataType,
	attachmentPrefix,
}: ItemContextWrapperProps) {
	//Context

	const { paymentChannels, customSaqList } = useReportAccess();
	const { workflows, interviews = [], hints = [] } = useReportContext();

	const { isProjectItem, isProjectInput, isSummary, isAttendeesList } = React.useMemo(() => {
		const isProjectEditableItem =
			!hasFlag(nodeType, NodeType.Template) &&
			!hasFlag(nodeType, NodeType.Ephemeral) &&
			type !== RocItemType.SummaryOfFindingsState;

		return {
			isProjectItem: isProjectEditableItem,
			isProjectInput: isProjectEditableItem && rocItemInputs.indexOf(type || 0) >= 0,
			isSummary:
				type === RocItemType.SummaryOfAssessmentFindingsState || type === RocItemType.Requirement,
			isAttendeesList: type === RocItemType.InterviewAttendee,
		};
	}, [type, nodeType]);

	const { workflowsList, channelStates, hasSaqValue } = React.useMemo(
		() =>
			getLists({
				workflows,
				itemId,
				paymentChannels,
				saq,
				data,
				customSaqList,
				summaryId,
				isSummary,
			}),
		[workflows, itemId, paymentChannels, saq, data, customSaqList, summaryId, isSummary],
	);

	//SAQ

	const [availablePCs, setAvailablePCs] = React.useState<AvailablePCsType>([]); //Payment Channel IDs

	const { isItemAvailable, allChannelStates } = React.useMemo(() => {
		const states: any[] = [
			...availablePCs,
			...channelStates,
			...extractPaymentChannelIds(data).map((cid) => ({ cid, status: CustomSaqStatus.Applicable })),
		].map((c) => ({ cid: c.cid, status: String(c.status) }));
		const applicableChannels = states.filter((p) => p.status === CustomSaqStatus.Applicable);
		return {
			isItemAvailable: !hasSaqValue || applicableChannels.length > 0 || workflowsList.length > 0,
			allChannelStates: states,
		};
	}, [availablePCs, channelStates, data, hasSaqValue, workflowsList.length]);

	const [isOverriding, setOverriding] = React.useState(false);

	const overrideSaqPC = React.useCallback(
		(pcId?: string) => {
			let overridable = false;

			setAvailablePCs((prevState) => {
				if (prevState.map((c) => c.cid).indexOf(pcId) >= 0) return prevState;

				overridable = true;

				return [...prevState, { cid: pcId, status: CustomSaqStatus.Applicable }];
			});

			if (overridable) setOverriding(true);
		},
		[setAvailablePCs],
	);

	//State

	const [context, setContext] = React.useState<ItemContextType>(itemContextInitial);

	React.useEffect(() => {
		setContext({
			...itemContextInitial,
			itemId,
			useDifferentValuesPerPaymentChannels,
			status,
			isItemApproved: status === ItemStatus.Approved,
			rocWarnings,
			hiddenWarnings,
			saq,
			modelType,
			lists: {
				workflows: workflowsList,
				interviews: interviews.filter(
					({ rocItemsIds }) => rocItemsIds && rocItemsIds.indexOf(itemId) >= 0,
				),
				hints: hints.filter(({ rocItemId }) => rocItemId === itemId),
				applicableChannelIds: ((useDifferentValuesPerPaymentChannels && saq != null) || isSummary
					? allChannelStates.filter(({ status }) => status === CustomSaqStatus.Applicable)
					: allChannelStates
				).map((c) => c.cid),
				allChannelStates,
			},
			overrideSaqPC,
			isOverriding,
			isItemAvailable,
			displayReasonsBox:
				(isSummary || useDifferentValuesPerPaymentChannels) &&
				allChannelStates.filter((c) => c.status !== CustomSaqStatus.Applicable).length > 0,
			itemProperties: {
				isSummary,
				isProjectItem,
				isProjectInput,
				isAttendeesList,
			},
			rocDataType,
			attachmentPrefix,
		});
	}, [
		setContext,
		itemId,
		availablePCs,
		useDifferentValuesPerPaymentChannels,
		status,
		rocWarnings,
		hiddenWarnings,
		saq,
		workflows,
		type,
		overrideSaqPC,
		isItemAvailable,
		isSummary,
		isProjectItem,
		isProjectInput,
		isAttendeesList,
		workflowsList,
		rocDataType,
		interviews,
		attachmentPrefix,
		modelType,
		hints,
		isOverriding,
		allChannelStates,
	]);

	return <ItemContext.Provider value={context}>{children as any}</ItemContext.Provider>;
}

//Non-input items don't need some calculations, so those are avoided using conditional context

export function TypeItemContextWrapper({ type, children, ...rest }: ItemContextWrapperProps) {
	if (type === undefined) return null;

	if (!isInputItem(type))
		return (
			<ItemContextWrapper type={type} {...rest}>
				{children}
			</ItemContextWrapper>
		);

	return (
		<InputItemContextWrapper type={type} {...rest}>
			{children}
		</InputItemContextWrapper>
	);
}

export function useItemContext(): ItemContextType {
	return React.useContext(ItemContext);
}
