import React from 'react';
import { SampleSet } from '../../../graphql/typescript';
import AttachmentData from '../AttachmentData';
import { useReportInfoContext } from '../../rocTemplates/context/ReportContext';
import { ReportType } from '../../core/types/api';

type SampleSetInfoProps = SampleSet & { changeHighlight?: () => void };

export default function SampleSetData({
	description,
	items = [],
	allItems,
	totalSampled,
	totalPopulation,
	...rest
}: SampleSetInfoProps) {
	const { version } = useReportInfoContext();

	const renderItems = React.useMemo(
		() =>
			items.length === 0 ? null : (
				<ul>
					{items.map(({ item, makeModelVersionRelease, totalSampled, totalPopulation }, idx) => (
						<li key={idx}>
							{item}
							<br />- Make / Model / Version / Release: {makeModelVersionRelease}
							<br />- Sampled: {totalSampled} / {totalPopulation}
						</li>
					))}
				</ul>
			),
		[items],
	);

	return (
		<AttachmentData {...rest}>
			<p>
				Type / Description: {description}
				<br />
				{(version === ReportType.PCI_DSS_v4 || version === ReportType.PCI_DSS_v4r1_AOC_SP_r2) && (
					<>
						Items: {allItems}
						<br />
						Sampled: {totalSampled} / {totalPopulation}
						<br />
					</>
				)}
				{renderItems}
			</p>
		</AttachmentData>
	);
}
