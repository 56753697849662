import React from 'react';
import { css } from '@emotion/css/macro';
import { get } from 'lodash';
import { FieldSet, HiddenDropdown } from '../../../core/components';
import JSONField from './JSONField';
import { styles } from '../../constants/styles';
import CheckboxGroup from '../../../ui/Checkbox/CheckboxGroup';

const rootClass = css`
	display: flex;
`;

const jsonFieldClass = css`
	flex: 1 1 auto;
	margin: 0 0.5rem 0 0;
`;

const stylesToSelectOptions = Object.keys(styles).map((key) => ({ label: key, value: key }));

type StyleAndAttributesFieldProps = { onChange: any; name: string; storage: any; title: string };

export default function StyleAndAttributesField({
	title,
	onChange,
	name,
	storage,
}: StyleAndAttributesFieldProps) {
	const classNamesStorage = `${name}.classNames`;

	const stylesChange = React.useCallback(
		(nextValue) => {
			onChange({ name: classNamesStorage, value: nextValue });
		},
		[onChange, classNamesStorage],
	);

	return (
		<FieldSet legend={title}>
			<div className={rootClass}>
				<JSONField
					className={jsonFieldClass}
					onChange={onChange}
					label="Config"
					name={name}
					storage={storage}
				/>
				<HiddenDropdown
					presentationElement={<button type="button">Styles</button>}
					hiddenElement={
						<CheckboxGroup
							value={get(storage, classNamesStorage, [])}
							onChange={stylesChange}
							id="stylesGroup"
							options={stylesToSelectOptions}
						/>
					}
				/>
			</div>
		</FieldSet>
	);
}
