import React from 'react';
import { Save } from '@mui/icons-material';
import { BasicForm, TextField } from '../../formaggio';
import { Button } from '../../core/components';
import { ProjectUserInfo } from '../../../graphql/typescript';
import { ValidationErrors } from '../../formaggio/types';
import { ResponsibilityAreaSelectorConnected } from '../../contact/components/ResponsibilityAreaSelector';
import { isClientRole } from '../../auth/helpers';
import ClientSelect from '../../client/ClientSelect';

type UserInfoFormProps = { firstName: string; lastName: string; clientId?: string };

const userInfoValidate = (values: UserInfoFormProps, isClient?: boolean) => {
	const errors: ValidationErrors<UserInfoFormProps> = {};
	if (!values.firstName.trim()) errors.firstName = true;
	if (!values.lastName.trim()) errors.lastName = true;
	if (isClient && !values.clientId) errors.clientId = 'Required field!';

	return errors;
};

type EditUserInfoFormProps = {
	onSuccess: () => void;
	userDetails: ProjectUserInfo;
	hideClientCompanyAssign?: boolean;
};

export default function EditUserInfoForm({
	onSuccess,
	userDetails,
	hideClientCompanyAssign,
}: EditUserInfoFormProps) {
	const isClient = isClientRole(userDetails.roles);
	const validate = React.useCallback((values) => userInfoValidate(values, isClient), [isClient]);

	return (
		<BasicForm
			fetchConfig={{
				query: 'account/EditUserInfo',
				method: 'PUT',
				successMessage: true,
				confirmation: true,
				qs: { userId: userDetails.id },
				onSuccess: () => onSuccess(),
			}}
			validate={validate}
			initialValues={{
				firstName: userDetails.firstName,
				lastName: userDetails.lastName,
				responsibilityArea: userDetails.userClient?.responsibilityArea,
				clientId: userDetails.userClient?.clientId,
				isPrimaryClient: userDetails.userClient?.isPrimary,
				phoneNumber: userDetails.phoneNumber,
			}}
			noResetOnSuccess
		>
			<TextField label="First Name" name="firstName" />
			<TextField label="Last Name" name="lastName" />
			<TextField label="Phone Number" name="phoneNumber" />
			{isClient && (
				<>
					{!hideClientCompanyAssign && <ClientSelect label="Client company" name="clientId" />}
					<ResponsibilityAreaSelectorConnected name="responsibilityArea" />
				</>
			)}
			<Button
				startIcon={<Save />}
				type="submit"
				color="primary"
				size="small"
				style={{ marginTop: '8px' }}
			>
				Save
			</Button>
		</BasicForm>
	);
}
