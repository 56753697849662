import { css, cx } from '@emotion/css/macro';
import { useToggle } from '../../core/hooks';
import { usePermalinkHighlightQS, useScrollToReportItem } from '../hooks';
import { TableStickyCell } from '../../../shared/components';
import { highlightCellClass } from '../LinkButton';
import OverlayPanel from '../OverlayPanel';
import InterviewedPersonEditWrapper from '../InterviewedPersonEdit';
import SummaryNAReasons from '../SummaryNAReasons';
import ProjectTypeInputWrapper from '../ProjectTypeInputWrapper';

const stickyCellClass = css`
	padding: 16px 0;
`;

// "1% width" will make table cells justify their size to content
const tableCellClass = css`
	vertical-align: top !important;
	padding: 0 16px 16px 16px !important;
	width: 1% !important;
`;

export function extractPaymentChannelIds(data: any[] = []) {
	return data.map(({ paymentChannelId }) => paymentChannelId) || [];
}

export default function TableCell({
	attributes,
	customAttributes,
	className,
	notApplicableReasons,
	rowId,
	cellIndex,
	itemId,
	children,
	itemDescription,
	subType,
	isAutoGenerated,
	renderType,
}: any) {
	//Context

	//Highlight
	const highlightQS = usePermalinkHighlightQS();
	const isRowHighlight = rowId === highlightQS && cellIndex === 0;
	const ref = useScrollToReportItem(isRowHighlight ? rowId : itemId);

	//Optional controls
	const { isOn, toggleOn, toggleOff } = useToggle();

	return (
		<TableStickyCell
			{...{ ...customAttributes, ...attributes, ...(renderType === 3 ? { colSpan: 2 } : {}) }}
			{...attributes}
			style={undefined}
			className={cx(
				className,
				tableCellClass,
				!!highlightQS && (itemId === highlightQS || isRowHighlight) && highlightCellClass,
			)}
			stickyContainerClassName={stickyCellClass}
			onFocus={toggleOn}
			onMouseEnter={toggleOn}
			onMouseLeave={toggleOff}
			ref={ref}
		>
			<OverlayPanel isOn={isOn} />
			<ProjectTypeInputWrapper>
				<SummaryNAReasons notApplicableReasons={notApplicableReasons}>{children}</SummaryNAReasons>
				{isAutoGenerated && subType === 'Int' && (
					<InterviewedPersonEditWrapper id={itemDescription} />
				)}
			</ProjectTypeInputWrapper>
		</TableStickyCell>
	);
}
