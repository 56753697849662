import { RolesEnum } from '../auth/enums';
import {
	AssessmentDocumentType,
	CreateReportGroup,
	DownloadFileType,
	ProjectType,
	SearchEntities,
} from './enums';
import { ExportDocumentsInfo } from './types';

export const companyInviteRoles = [
	RolesEnum.MasterAdministrator,
	RolesEnum.ProjectManager,
	RolesEnum.Qsa,
	RolesEnum.AssociateQsa,
	RolesEnum.Client,
	RolesEnum.Auditor,
	RolesEnum.ExecutiveClientRepresentative,
	RolesEnum.TechnicalEditor,
];

export const projectInviteRoles = [
	RolesEnum.ProjectManager,
	RolesEnum.Qsa,
	RolesEnum.AssociateQsa,
	RolesEnum.Client,
	RolesEnum.Auditor,
	RolesEnum.ExecutiveClientRepresentative,
	RolesEnum.TechnicalEditor,
];

export const reportAssetGroups: Partial<
	Record<CreateReportGroup, { title: string; description?: string; forDifferentClient: boolean }>
> = {
	[CreateReportGroup.ROCContactInformation]: {
		title: 'Contact Information',
		description: 'ISA list and below',
		forDifferentClient: false,
	},
	[CreateReportGroup.ROCRemoteAssessmentActivities]: {
		title: 'Remote Assessment Activities',
		forDifferentClient: true,
	},
	[CreateReportGroup.ROCAdditionalServices]: {
		title: 'Additional services',
		description: 'provided by QSA Company',
		forDifferentClient: true,
	},
	[CreateReportGroup.ROCUseOfSubcontractors]: {
		title: 'Use of subcontractors',
		forDifferentClient: true,
	},
	[CreateReportGroup.ROCAdditionalInformation]: {
		title: 'Additional information/reporting',
		forDifferentClient: true,
	},
	[CreateReportGroup.ROCBusinessOverview]: {
		title: 'Business Overview',
		forDifferentClient: true,
	},
	[CreateReportGroup.ROCAssessorValidationOfDefinedScopeAccuracy]: {
		title: 'Assessor validation of defined scope accuracy',
		forDifferentClient: true,
	},
	[CreateReportGroup.ROCSegmentation]: {
		title: 'Segmentation',
		forDifferentClient: true,
	},
	[CreateReportGroup.ROCPCISSCValidatedProductsAndSolutions]: {
		title: 'PCI-SSC validated products and solutions',
		forDifferentClient: true,
	},
	[CreateReportGroup.ROCSampling]: {
		title: 'Sampling',
		forDifferentClient: true,
	},
	[CreateReportGroup.ROCNetworkDiagrams]: {
		title: 'Network diagrams',
		forDifferentClient: true,
	},
	[CreateReportGroup.ROCAccountDataFlowDiagrams]: {
		title: 'Account dataflow diagrams',
		forDifferentClient: true,
	},
	[CreateReportGroup.ROCStorageOfAccountData]: {
		title: 'Storage of account data',
		forDifferentClient: true,
	},
	[CreateReportGroup.ROCInScopeThirdPartyServiceProviders]: {
		title: 'In-scope third party service providers',
		forDifferentClient: true,
	},
	[CreateReportGroup.ROCInScopeNetworks]: {
		title: 'In-scope networks',
		forDifferentClient: true,
	},
	[CreateReportGroup.ROCInScopeLocationsFacilities]: {
		title: 'In-scope locations/facilities',
		forDifferentClient: false,
	},
	[CreateReportGroup.ROCInScopeBusinessFunctions]: {
		title: 'In-scope business functions',
		forDifferentClient: true,
	},
	[CreateReportGroup.ROCInScopeSystemComponentTypes]: {
		title: 'In-scope system component types',
		forDifferentClient: true,
	},
	[CreateReportGroup.ROCEvidenceRetention]: {
		title: 'Evidence retention',
		forDifferentClient: true,
	},
	[CreateReportGroup.ROCFindings]: {
		title: 'Findings and Observations',
		description: 'All data including sub-requirement responses',
		forDifferentClient: false,
	},
	[CreateReportGroup.ROCFindingsRequirementsOnly]: {
		title: 'Findings and Observations',
		description: 'Requirement responses only',
		forDifferentClient: true,
	},
	[CreateReportGroup.Workflows]: {
		title: 'Workflows',
		description: 'Comments, status changes, etc.',
		forDifferentClient: false,
	},
};

export const commonExportTypes: ExportDocumentsInfo = {
	[AssessmentDocumentType.WorkPapersExcel]: {
		title: 'Workpapers Sheet',
		fileName: 'Workpapers_Sheet',
		fileType: DownloadFileType.Excel,
	},
	[AssessmentDocumentType.WorkPapersArchive]: {
		title: 'Workpapers Archive',
		fileName: 'Workpapers_Arcihve',
		fileType: DownloadFileType.Zip,
	},
	[AssessmentDocumentType.RemediationList]: {
		title: 'Remediation List',
		fileName: 'Remediation_List',
		fileType: DownloadFileType.Excel,
		customerAccessible: true,
	},
	[AssessmentDocumentType.StatusReport]: {
		title: `Status Report (Last 7 Days)`,
		fileName: 'Status_Report',
		fileType: DownloadFileType.Word,
		customerAccessible: false,
	},
};

const v321ProjectTypes: ExportDocumentsInfo = {
	[AssessmentDocumentType.ReportOnCompliance]: {
		id: 'd05a7b61-7e8c-453f-ac30-267cbab96640',
		title: 'Report on Compliance',
		fileName: 'ROC',
		fileType: DownloadFileType.Word,
		isDefault: true,
		isMain: true,
	},
	[AssessmentDocumentType.AttestationOfComplianceMerchant]: {
		id: '00000000-0000-0000-0000-000000000001',
		title: 'AoC: Merchants',
		fileName: 'AOC_Merchants',
		fileType: DownloadFileType.Word,
		isMain: true,
	},
	[AssessmentDocumentType.AttestationOfComplianceServiceProviders]: {
		id: '00000000-0000-0000-0000-000000000002',
		title: 'AOC: Service Providers',
		fileName: 'AOC_Service_Providers',
		fileType: DownloadFileType.Word,
		isMain: true,
	},
	[AssessmentDocumentType.RemoteAssessmentAddendum]: {
		id: '00000000-0000-0000-0000-000000000003',
		title: 'Remote Assessment Addendum',
		fileName: 'ROC_Remote_Assessment_Addendum',
		fileType: DownloadFileType.Word,
		isMain: true,
	},
};

const v321r2ProjectTypes = v321ProjectTypes;

const v4ProjectTypes: ExportDocumentsInfo = {
	[AssessmentDocumentType.ReportOnCompliance]: {
		id: '00000000-0000-0000-0000-000000000001',
		title: 'Report on Compliance',
		fileName: 'ROC',
		fileType: DownloadFileType.Word,
		isDefault: true,
		isMain: true,
	},
	[AssessmentDocumentType.AttestationOfComplianceMerchant]: {
		id: '00000000-0000-0000-0000-000000000002',
		title: 'AoC: Merchants',
		fileName: 'AOC_Merchants',
		fileType: DownloadFileType.Word,
		isMultiLanguage: true,
		isMain: true,
	},
	[AssessmentDocumentType.AttestationOfComplianceServiceProviders]: {
		id: '00000000-0000-0000-0000-000000000003',
		title: 'AOC: Service Providers',
		fileName: 'AOC_Service_Providers',
		fileType: DownloadFileType.Word,
		isMultiLanguage: true,
		isMain: true,
	},
	[AssessmentDocumentType.SupplementalAttestationOfCompliance]: {
		id: '00000000-0000-0000-0000-000000000004',
		title: 'Supplemental Attestation Of Compliance',
		fileName: 'AOC_Supplemental',
		fileType: DownloadFileType.Word,
		isMain: true,
	},
	[AssessmentDocumentType.ItemsNotedForImprovement]: {
		id: '00000000-0000-0000-0000-000000000005',
		title: 'Items Noted for Improvement (deprecated March 19th 2024)',
		fileName: 'INFI',
		fileType: DownloadFileType.Word,
		isMultiLanguage: true,
	},
};

const v4r1AocSpr2ProjectTypes = v4ProjectTypes;

const fedRampRev5ProjectTypes: ExportDocumentsInfo = {
	[AssessmentDocumentType.InitialAuthorizationPackageChecklist]: {
		id: '00000000-0000-0000-0000-000000000008',
		title: 'Initial Authorization Package Checklist',
		fileName: 'Initial_Authorization_Package_Checklist',
		fileType: DownloadFileType.Excel,
		isDefault: true,
		isMain: true,
	},
	[AssessmentDocumentType.ModerateReadinessAssessmentReport]: {
		id: '00000000-0000-0000-0000-000000000003',
		title: 'Moderate Readiness Assessment Report',
		fileName: 'Moderate_Readiness_Assessment_Report',
		fileType: DownloadFileType.Word,
		isMain: true,
	},
	[AssessmentDocumentType.SystemSecurityPlanAppendixAModerate]: {
		id: '00000000-0000-0000-0000-000000000001',
		title: 'SSP Appendix A Moderate',
		fileName: 'SSP_Appendix_A_Moderate',
		fileType: DownloadFileType.Word,
		isMain: true,
	},
};

export const projectTypes: ExportDocumentsInfo[] = [
	v321ProjectTypes,
	v4ProjectTypes,
	fedRampRev5ProjectTypes,
	v321r2ProjectTypes,
	null, // Scope Builder
	v4r1AocSpr2ProjectTypes,
];

// Project model types

export const pciDssModelTypes = {
	[ProjectType.Merchant]: { name: 'Merchant' },
	[ProjectType.ServiceProvider]: { name: 'Service Provider' },
	[ProjectType.SharedHostingProvider]: { name: 'Shared Hosting Provider' },
};

export const fedRampModelTypes = {
	// [ProjectType.LowReadiness]: { name: 'Low Readiness' },
	[ProjectType.ModerateReadiness]: { name: 'Moderate Readiness' },
	// [ProjectType.HighReadiness]: { name: 'High Readiness' },
};

export const projectModelTypes: Partial<Record<ProjectType, { name: string }>>[] = [
	pciDssModelTypes,
	pciDssModelTypes,
	fedRampModelTypes,
	pciDssModelTypes,
];

// Project date filter

export const PROJECT_FILTER_KEY = 'projectFilter';

export enum ProjectDateFilter {
	All = 'All',
	LastQuarter = 'LastQuarter',
	LastYear = 'LastYear',
}

export const defaultProjectFilter = {
	isActive: true,
	dateFilter: ProjectDateFilter.LastQuarter,
};

// Search

export const projectSearchEntities = {
	[SearchEntities.Attachments]: { title: 'Attachments' },
	[SearchEntities.Workflows]: { title: 'Workflows' },
	[SearchEntities.ReportTextBlocks]: { title: 'Text blocks' },
	[SearchEntities.ReportTextFields]: { title: 'Text fields' },
	[SearchEntities.ReportEvidenceLocations]: { title: 'Evidence locations' },
};
