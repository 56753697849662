import React from 'react';
import { Box, BoxProps } from '@mui/material';
import { darkness, mainLight } from '../../ui/Core/stylesheets/colors';
import { getContrastText } from '../../ui/Core/helpers';

type CounterProps = {
	count?: number;
	children: React.ReactNode;
	secondary?: boolean;
	color?: string;
	counterProps?: BoxProps;
} & BoxProps;

function Counter({
	count,
	children,
	secondary,
	color = mainLight,
	counterProps = {},
	...rest
}: CounterProps) {
	if (!count || count < 0) return <>{children}</>;

	return (
		<Box display="flex" alignItems="center" {...rest}>
			<Box display="flex" alignItems="center">
				{children}
			</Box>
			<Box
				fontWeight="300"
				color={secondary ? darkness : getContrastText(color)}
				fontSize="13px"
				padding="4px 6px"
				borderRadius="16px"
				bgcolor={secondary ? 'white' : color}
				marginLeft="8px"
				lineHeight="10px"
				border={secondary ? `1px solid ${darkness}` : undefined}
				{...counterProps}
			>
				{count}
			</Box>
		</Box>
	);
}

export default React.memo(Counter);
