import React from 'react';
import {
	Array,
	Attachment,
	Bool,
	CompensatingControl,
	Container,
	Date,
	DateRange,
	InterviewAttendee,
	OneMultipleRow,
	RadioFlag,
	Requirement,
	SoAFS,
	TableRow,
	TextBlock,
	TextField,
} from '../rocItems';
import SummaryOfAssessment from '../rocItems/SummaryOfAssessment';
import SummaryOfRequirements from '../rocItems/SummaryOfRequirements';
import AocSummaryOfRequirements from '../rocItems/AocSummaryOfRequirements';
import { RocItemType, SummaryOfAssessmentState } from '../enums';
import InterviewEvent from '../rocItems/InterviewEvent';
import { isProduction } from '../../core/constants/config';
import FedRampControl from '../rocItems/fedRamp/FedRampControl';
import OscalTextField from '../rocItems/fedRamp/OscalTextField';
import OscalChoiceField from '../rocItems/fedRamp/OscalChoiceField';
import FedRampControlSummary from '../rocItems/fedRamp/FedRampControlSummary';
import { ChannelType, ContainerRocItemType, ReportType, Saq } from '../../core/types/api';
import { LicenseStatus } from '../../hubs/LicenseStatus';
import { recordToSelectOptions } from '../../formaggio/components/SelectField';
import { FeatureFlag, isFeatureEnabled, isScopeBuilderEnabled } from '../../license/featureFlags';

export { ReportType } from '../../core/types/api';

export const SAQSelect = [
	{ title: 'All Requirements', value: Saq.All },
	{ title: 'A', value: Saq.A },
	{ title: 'A-EP', value: Saq.Aep },
	{ title: 'B', value: Saq.B },
	{ title: 'B-IP', value: Saq.Bip },
	{ title: 'C', value: Saq.C },
	{ title: 'C-VT', value: Saq.Cvt },
	{ title: 'P2PE', value: Saq.P2Pe },
];

export const PaymentChannelTypeSelect = [
	{ title: 'Payment', value: ChannelType.Payment },
	{ title: 'Service', value: ChannelType.Service },
];

type RocItemTypeProps = Record<string, { name: string; component: React.ComponentType<any> }>;

export const rocItemTypes: RocItemTypeProps = {
	[RocItemType.TextField]: { name: 'Text Field', component: TextField },
	[RocItemType.Attachment]: { name: 'Attachment', component: Attachment },
	[RocItemType.Array]: { name: 'Array', component: Array },
	[RocItemType.Bool]: { name: 'Bool', component: Bool },
	[RocItemType.Date]: { name: 'Date', component: Date },
	[RocItemType.DateRange]: { name: 'DateRange', component: DateRange },
	[RocItemType.Container]: { name: 'Container', component: Container },
	[RocItemType.TextBlock]: { name: 'Text Block', component: TextBlock },
	[RocItemType.SummaryOfAssessmentFindingsState]: {
		name: 'Summary Of Assessment Findings State',
		component: SoAFS,
	},
	[RocItemType.TableRow]: { name: 'Table Row (PCI-DSS only, obsolete)', component: TableRow },
	[RocItemType.OneMultipleRow]: { name: 'One Multiple Row', component: OneMultipleRow },
	[RocItemType.CompensatingControl]: {
		name: 'Compensating Control',
		component: CompensatingControl,
	},
	[RocItemType.SummaryOfFindingsState]: {
		name: 'Summary Of Findings',
		component: SummaryOfAssessment,
	},
	[RocItemType.InterviewAttendee]: { name: 'Interview Attendee', component: InterviewAttendee },
	[RocItemType.Requirement]: { name: 'Requirement (PCI DSS v4.0)', component: Requirement },
	[RocItemType.InterviewEvent]: {
		name: 'Interview Event  (PCI DSS v4.0)',
		component: InterviewEvent,
	},
	[RocItemType.RadioFlag]: {
		name: 'Radio / Flag',
		component: RadioFlag,
	},
	[RocItemType.SummaryOfRequirements]: {
		name: 'Summary Of Requirements (PCI DSS v4.0)',
		component: SummaryOfRequirements,
	},
	[RocItemType.AocSummaryOfRequirements]: {
		name: 'AOC Compliant to PCI DSS Requirements (PCI DSS v4.0)',
		component: AocSummaryOfRequirements,
	},
	[RocItemType.FedRampControl]: {
		name: 'FedRAMP rev.5 Control',
		component: FedRampControl,
	},
	[RocItemType.OscalTextField]: {
		name: 'OSCAL (FedRAMP) Text Field',
		component: OscalTextField,
	},
	[RocItemType.OscalChoiceField]: {
		name: 'OSCAL (FedRAMP) Choice Field',
		component: OscalChoiceField,
	},
	[RocItemType.FedRampControlSummary]: {
		name: 'FedRAMP rev.5 Control Summary',
		component: FedRampControlSummary,
	},
};

export const rocItemInputs = [
	RocItemType.TextField,
	RocItemType.Attachment,
	RocItemType.Bool,
	RocItemType.Date,
	RocItemType.DateRange,
	RocItemType.SummaryOfAssessmentFindingsState,
	RocItemType.SummaryOfFindingsState,
	RocItemType.InterviewAttendee,
	RocItemType.InterviewEvent,
	RocItemType.Requirement,
	RocItemType.RadioFlag,
];

function rocItemTypesToSelect() {
	return Object.keys(rocItemTypes).map((type: string) => {
		const { name } = rocItemTypes[type];
		return { title: name, value: type };
	});
}

export const rocItemTypesSelect = rocItemTypesToSelect();

export const containerTypes = [
	{ title: 'Block', value: ContainerRocItemType.Block },
	{ title: 'Table', value: ContainerRocItemType.Table },
	{ title: 'Fragment (no wrapping tags)', value: ContainerRocItemType.Empty },
	{ title: 'FedRAMP rev.5 Control', value: ContainerRocItemType.FedRampControl },
	{ title: 'Row', value: ContainerRocItemType.Row },
];

export enum ReportOrigin {
	PCI_DSS,
	FedRAMP,
}

export const reportOrigins: Record<ReportOrigin, { name: string }> = {
	[ReportOrigin.PCI_DSS]: { name: 'PCI-DSS' },
	[ReportOrigin.FedRAMP]: { name: 'FedRAMP' },
};

const templates: Record<ReportType, { origin: ReportOrigin; version: string }> = {
	[ReportType.PCI_DSS_v321]: { origin: ReportOrigin.PCI_DSS, version: 'v3.2.1' },
	[ReportType.PCI_DSS_v321r2]: { origin: ReportOrigin.PCI_DSS, version: 'v3.2.1 r2' },
	[ReportType.PCI_DSS_v4]: { origin: ReportOrigin.PCI_DSS, version: 'v4.0' },
	[ReportType.PCI_DSS_v4r1_AOC_SP_r2]: { origin: ReportOrigin.PCI_DSS, version: 'v4.0 r1/r2' },
	[ReportType.FedRAMP_rev5]: { origin: ReportOrigin.FedRAMP, version: 'rev.5 (WIP)' },
	[ReportType.PCI_DSS_Scope_Builder]: {
		origin: ReportOrigin.PCI_DSS,
		version: 'Scope Builder (WIP)',
	},
};

export const templateReportTypeMigrations = [
	{ reportType: ReportType.PCI_DSS_v321, up: ReportType.PCI_DSS_v321r2, down: undefined },
	{
		reportType: ReportType.PCI_DSS_v321r2,
		up: undefined,
		down: ReportType.PCI_DSS_v321,
		featureFlag: FeatureFlag.DowngradePci321r2To321r1,
	},
	{ reportType: ReportType.PCI_DSS_v4, up: ReportType.PCI_DSS_v4r1_AOC_SP_r2, down: undefined },
	{
		reportType: ReportType.PCI_DSS_v4r1_AOC_SP_r2,
		up: undefined,
		down: ReportType.PCI_DSS_v4,
		featureFlag: FeatureFlag.DowngradePci40r1r2To40r1,
	},
];

export function getVersionName(reportType: number) {
	const found = templates[reportType];
	return found ? found.version : 'unknown';
}

export function getPossibleReportTypeMigrations(
	reportType: number,
	licensing: LicenseStatus,
): {
	up: number | undefined;
	down: number | undefined;
} {
	const found = templateReportTypeMigrations.filter(
		(t) =>
			t.reportType === reportType &&
			(t.featureFlag == null || isFeatureEnabled(licensing, t.featureFlag)),
	);
	if (found.length === 0) return { down: undefined, up: undefined };
	return found[0];
}

function templatesToSelectOptions(keys: ReportType[]) {
	return recordToSelectOptions(
		templates,
		keys,
		(t) => `${reportOrigins[t.origin].name} ${t.version}`,
	);
}

export const saqVersionSelect = templatesToSelectOptions([
	ReportType.PCI_DSS_v321r2,
	ReportType.PCI_DSS_v4r1_AOC_SP_r2,
]);

export const templateVersionSelect = () => [
	...saqVersionSelect,
	...templatesToSelectOptions([
		ReportType.PCI_DSS_v4,
		...(isScopeBuilderEnabled() ? [ReportType.PCI_DSS_Scope_Builder] : []),
		...(isProduction ? [] : [ReportType.FedRAMP_rev5]),
	]),
];

export const filterVersionSelect = [
	...templateVersionSelect(),
	...templatesToSelectOptions([ReportType.PCI_DSS_v321]),
];

export const summaryOfAssessmentTypes: Record<SummaryOfAssessmentState, { title: string }> = {
	[SummaryOfAssessmentState.InPlace]: {
		title: 'In Place',
	},
	[SummaryOfAssessmentState.InPlaceW_CCW]: {
		title: 'In Place w/ CCW',
	},
	[SummaryOfAssessmentState.NA]: {
		title: 'N/A',
	},
	[SummaryOfAssessmentState.NotInPlace]: {
		title: 'Not In Place',
	},
	[SummaryOfAssessmentState.NotTested]: {
		title: 'Not Tested',
	},
};
