import React from 'react';
import { css, cx } from '@emotion/css/macro';
import { spacing } from '../Core/stylesheets/spacing';
import { mainLight } from '../Core/stylesheets/colors';
import { wordBreak } from '../Core/stylesheets/text';

const baseCss = css`
	display: flex;
	align-items: center;
	position: relative;
`;

const inputCss = css`
	opacity: 0;
	height: 2rem;
	width: 2rem;
	margin: 0.5rem 0; // margin should be enough to add up to 3rem with given height
	flex-shrink: 0;
	z-index: 1;
	cursor: pointer;

	&:checked + span::before {
		opacity: 1;
	}

	&:focus + span {
		outline: 2px solid ${mainLight};
	}

	// TODO add disabled state
`;

const boxCss = css`
	display: block;
	height: 2rem;
	width: 2rem;
	position: absolute;
	left: 0;
	text-align: center;
	/* TODO should come from a constant */
	background-color: #eee;

	&::before {
		content: '\\2713';
		font-size: 2rem;
		line-height: 2rem;
		opacity: 0;
	}
`;

const labelCss = css`
	${wordBreak};
	padding-left: ${spacing.small};
	cursor: pointer;
`;

export type CheckboxInputProps = Omit<JSX.IntrinsicElements['input'], 'type' | 'id'> & {
	id: string;
	label?: React.ReactNode;
};

export default function CheckboxInput({ label, id, className, ...rest }: CheckboxInputProps) {
	return (
		<div className={cx(baseCss, className)}>
			<input type="checkbox" id={id} className={inputCss} {...rest} />
			<span className={boxCss} />
			{label && (
				<label htmlFor={id} className={labelCss}>
					{label}
				</label>
			)}
		</div>
	);
}
