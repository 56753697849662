import { givenRolesToSelectOptions } from './helpers';

export const AUTH_TOKEN_KEY = 'authToken';
export const LAST_PATH_KEY = 'return';
export const PRELOGIN_KEY = 'userName';

export const roleTitles: Record<number, string> = {
	1: '🧙',
	2: 'Master Administrator',
	//4: 'Client Administrator', to be merged with MA
	8: 'Project Manager',
	16: 'QSA',
	32: 'Associate QSA',
	64: 'Technical Editor',
	128: 'Client',
	256: 'Contact',
	512: 'Auditor',
	1024: 'Executive Client Representative',
};

export const MAX_ROLE_VALUE = Number.MAX_SAFE_INTEGER;

export const allRolesFlags = Object.keys(roleTitles)
	.map(Number)
	.filter((t) => t !== 256);

export const allRolesWithoutCEMOptions = givenRolesToSelectOptions(
	allRolesFlags.filter((t) => t !== 1024),
);
