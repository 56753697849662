import React from 'react';
import { css, cx } from '@emotion/css/macro';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import { DeleteForever, History, Save, Search } from '@mui/icons-material';
import { Chip } from '@mui/material';
import { Heading, Select } from '../core/components';
import { spacing } from '../ui/Core/stylesheets/spacing';
import { convertToHumanTime, hasFlag } from '../core/helpers';
import { TIME_FORMAT } from '../core/constants/time';
import { useAllowedMethods, useReportAccess } from '../auth/hooks';
import { RocDataAction, RocDataType } from './enums';
import { ALL_PAYMENT_CHANNELS } from './UserData';
import { useAuthContext } from '../auth/context';
import { useToggle, useWindowCloseConfirmation } from '../core/hooks';
import { useReportContext } from './context/ReportContext';
import WorkflowAuthor from '../workflow/components/WorkflowAuthor';
import { useItemContext } from './context/ItemContext';
import LookUp from '../lookUp/LookUp';
import MaterialFlyOut from '../flyout/components/MaterialFlyOut';
import SimplifiedButton from './simplifiedControls/SimplifiedButton';
import { isPciDssVersion } from './helpers';
import TextHistory from '../textHistory/TextHistory';
import { ReportType } from '../core/types/api';
import ScopeItemInfo from '../scopeBuilder/components/ScopeItemInfo';

const rootClass = css`
	min-width: 150px;
`;

const headerClass = css`
	display: flex;
	align-items: center;
	margin-bottom: 4px;
`;

const responseHeaderClass = css`
	margin-top: ${spacing.small};
	display: flex;
	align-items: center;
`;

const responseControlsClass = css`
	display: flex;
	margin: ${spacing.xsmall} 0;
	align-items: center;
`;

const notSavedClass = css`
	font-size: 13px;
	margin: 4px 0;
`;

const dateClass = css`
	margin-right: 8px !important;
`;

function setEditData(prevData: object[], dataToAppend: object, idx: number) {
	const prevTempData = [...prevData];
	const prevOldDataObj = { ...prevData[idx] };
	prevTempData[idx] = { ...prevOldDataObj, ...dataToAppend };
	return prevTempData;
}

function UserInput({
	initialValue = {},
	retrievedValue,
	isNewMode,
	useDifferentValuesPerPaymentChannels,
	autoPCId,
	paymentChannelId: initialPCId = useDifferentValuesPerPaymentChannels && !isNewMode
		? ALL_PAYMENT_CHANNELS
		: autoPCId,
	manyPaymentChannels,
	responseId,
	fetchAPI,
	inputElement: InputElement,
	presentationElement: PresentationElement = InputElement,
	className,
	idx,
	emptyValue,
	editTime,
	setDataState,
	additional = {},
	disclosureValueToSend,
	createdBy,
	rocDataType,
	//multipleResponses,
	paymentChannelsSelectOptions,
	showDivider,
	writePermissionMask,
	isReadOnly,
	manualSave,
	validation,
	instantSave,
	isArrayChild,
	version,
	appliedScopeInfo,
	isAutoModified,
}: any) {
	const [value, setValue] = React.useState(initialValue);

	//null means all
	const [PCId, setPCId] = React.useState<string | null>(
		useDifferentValuesPerPaymentChannels && !isNewMode && !initialPCId
			? ALL_PAYMENT_CHANNELS
			: initialPCId,
	);

	const inputRef = React.useRef<HTMLInputElement>();

	const stringifiedInitialValue = JSON.stringify(initialValue);
	React.useEffect(() => {
		setValue(JSON.parse(stringifiedInitialValue));
		setPCId(initialPCId);
	}, [setValue, stringifiedInitialValue, setPCId, initialPCId]);

	const [timer, setTimer] = React.useState<any>(undefined);

	const clearTimer = React.useCallback(() => {
		clearTimeout(timer);
		setTimer(undefined);
	}, [setTimer, timer]);

	const clearAllInputs = React.useCallback(() => {
		setValue(initialValue);
		setPCId(null);
	}, [setValue, setPCId, initialValue]);

	//eslint-disable-next-line arrow-body-style
	const isResponseChanged = React.useMemo(() => {
		//console.log(value, initialValue, ' - ', PCId, initialPCId);
		//console.log(value !== initialValue, ' - ', PCId !== initialPCId);
		return JSON.stringify(value) !== JSON.stringify(initialValue) || PCId !== initialPCId;
	}, [value, initialValue, PCId, initialPCId]);

	useWindowCloseConfirmation(isResponseChanged);

	const {
		'RocItemControllerNew/report/deleteValue': canDeleteResponse,
		'RocLookUp/View': canLookUp,
	} = useAllowedMethods();
	const { projectId, users, contacts, refresh } = useReportContext();
	const { isAQSA, role } = useReportAccess();
	const { id: myId, globalOptions } = useAuthContext();
	const { isItemApproved, itemId } = useItemContext();

	//Condition checks
	const isValueEmpty = React.useMemo(
		() => JSON.stringify(value) === JSON.stringify(emptyValue),
		[value, emptyValue],
	);

	//Validation handlers
	const validationMessage = React.useMemo(
		() => (validation ? validation(value) : undefined),
		[validation, value],
	);

	//Save handle
	const save = React.useCallback(() => {
		if (validationMessage) return;

		const changedPCId = PCId === ALL_PAYMENT_CHANNELS ? undefined : PCId;

		const params: any = {
			paymentChannelId: changedPCId,
		};

		if (responseId) params.id = responseId;
		if (disclosureValueToSend) params.disclosureSummary = disclosureValueToSend;
		params.rocDataType = rocDataType;
		const action = responseId ? RocDataAction.Update : RocDataAction.Insert;

		fetchAPI({
			query: `RocItemControllerNew/report/setValue/${projectId}/${itemId}/${action}`,
			method: 'POST',
			params: { ...params, ...value },
			onSuccess: ({ id: responseId }: any) => {
				if (isNewMode) clearAllInputs();
				setDataState((prevData: object[]) => {
					if (isNewMode) {
						return [
							...prevData,
							{ ...value, editorId: myId, id: responseId, paymentChannelId: changedPCId },
						];
					}
					return setEditData(prevData, { ...value, paymentChannelId: changedPCId }, idx);
				});
				refresh();
			},
		});
	}, [
		validationMessage,
		PCId,
		responseId,
		disclosureValueToSend,
		rocDataType,
		fetchAPI,
		projectId,
		itemId,
		value,
		isNewMode,
		clearAllInputs,
		setDataState,
		refresh,
		idx,
		myId,
	]);

	//Save blur handlers
	const isNotSaveable = React.useMemo(
		() => validationMessage || manualSave,
		[manualSave, validationMessage],
	);
	const handleSave = React.useCallback(() => {
		if (isNotSaveable) return;
		save();
	}, [isNotSaveable, save]);
	const [saveTrigger, setSaveTrigger] = React.useState(false);
	const triggerSaveCheck = React.useCallback(() => {
		if (instantSave) return;

		setSaveTrigger(true);
	}, [instantSave]);

	React.useEffect(() => {
		if (isResponseChanged && saveTrigger) {
			setSaveTrigger(false);
			handleSave();
		}
	}, [handleSave, isResponseChanged, saveTrigger]);

	//Remove handle
	const remove = React.useCallback(() => {
		clearTimer();
		fetchAPI({
			query: `RocItemControllerNew/report/deleteValue/${projectId}/${itemId}/${responseId}`,
			method: 'DELETE',
			onSuccess: () => {
				setDataState((prevData: object[]) => {
					const tempPrevData = [...prevData];
					tempPrevData.splice(idx, 1);
					return tempPrevData;
				});
				refresh();
			},
			confirmation: true,
		});
	}, [fetchAPI, responseId, idx, setDataState, projectId, itemId, clearTimer, refresh]);

	//Field handlers
	const changeValue = React.useCallback(
		(e) => {
			setValue(retrievedValue(e, value));
			if (instantSave) setSaveTrigger(true);
		},
		[instantSave, retrievedValue, value],
	);

	const changePC = React.useCallback(
		(e) => {
			setPCId(e.target.value || null);
			triggerSaveCheck();
		},
		[triggerSaveCheck],
	);

	//Look Up
	const { isOn: isLookUpOn, toggleOn: lookUpOn, toggleOff: lookUpOff } = useToggle();

	//Text History

	const showTextHistory = rocDataType === RocDataType.Text && version !== ReportType.FedRAMP_rev5; //TODO: additional validation

	const { isOn: isHistoryOn, toggleOn: historyOn, toggleOff: historyOff } = useToggle();

	//Other
	const other = React.useMemo(() => {
		const isWritable = !writePermissionMask || hasFlag(writePermissionMask, role);
		const isEditable = (isAQSA || isWritable) && !isReadOnly && !isItemApproved;
		const isDeleteable = canDeleteResponse && isEditable && !isNewMode;
		const responseDate = convertToHumanTime(editTime || new Date(), TIME_FORMAT.DATE_ONLY);

		return { isEditable, isDeleteable, responseDate };
	}, [
		canDeleteResponse,
		editTime,
		isAQSA,
		isItemApproved,
		isNewMode,
		isReadOnly,
		role,
		writePermissionMask,
	]);

	const showInput =
		!useDifferentValuesPerPaymentChannels ||
		(manyPaymentChannels ? globalOptions.allowResponsesWithoutChannel || !!PCId : true);

	const showLookUp =
		isNewMode &&
		canLookUp &&
		rocDataType !== RocDataType.SummaryOfAssessmentFindingsState &&
		rocDataType !== RocDataType.InterviewAttendee &&
		rocDataType !== RocDataType.SummaryOfRequirements &&
		rocDataType !== RocDataType.AocSummaryOfRequirements &&
		rocDataType !== RocDataType.SummaryOfFindingsState &&
		!isArrayChild &&
		isPciDssVersion(version);

	return (
		<>
			<div className={cx(rootClass, className)}>
				<div className={headerClass}>
					{useDifferentValuesPerPaymentChannels && (
						<FormControl
							style={{ marginRight: '0.5rem' }}
							disabled={!other.isEditable || !manyPaymentChannels}
						>
							<InputLabel>Channel</InputLabel>
							<Select
								value={PCId || ''}
								onChange={changePC}
								options={paymentChannelsSelectOptions}
							/>
						</FormControl>
					)}
					{!isNewMode && (
						<div className={responseHeaderClass}>
							<Heading variant="body2" color="textSecondary" className={dateClass}>
								{other.responseDate}
							</Heading>
							<WorkflowAuthor {...createdBy} users={users} contacts={contacts} />
						</div>
					)}
				</div>
				{isAutoModified && <ScopeItemInfo appliedScopeInfo={appliedScopeInfo} />}
				{other.isEditable ? (
					<>
						{showInput && (
							<>
								<InputElement
									error={isValueEmpty && !isNewMode}
									value={value}
									onBlur={triggerSaveCheck}
									onChange={changeValue}
									onFocus={() => undefined}
									id={responseId}
									additional={{ ...additional, isNewMode, PCId }}
									ref={rocDataType === RocDataType.Text ? inputRef : undefined}
								/>
								<Box display="flex" gridGap={2}>
									{showLookUp && (
										<SimplifiedButton startIcon={<Search />} tabIndex={-1} onClick={lookUpOn}>
											Look Up
										</SimplifiedButton>
									)}
									{showTextHistory && (
										<SimplifiedButton startIcon={<History />} tabIndex={-1} onClick={historyOn}>
											History
										</SimplifiedButton>
									)}
								</Box>
							</>
						)}
					</>
				) : (
					<PresentationElement
						value={value}
						additional={{ ...additional, isNewMode }}
						disabled={!other.isEditable}
					/>
				)}
				{other.isDeleteable && (
					<div className={responseControlsClass}>
						<SimplifiedButton onClick={remove} tabIndex={-1} startIcon={<DeleteForever />}>
							Delete Response
						</SimplifiedButton>
					</div>
				)}
				{isResponseChanged && (
					<div className={notSavedClass}>
						{validationMessage ? (
							<Chip color="error" variant="outlined" label={validationMessage} />
						) : (
							<>
								{manualSave ? (
									<SimplifiedButton startIcon={<Save />} onClick={save} tabIndex={-1}>
										Save response
									</SimplifiedButton>
								) : (
									<>
										{!instantSave && (
											<Chip
												variant="outlined"
												label="Click away or navigate to next field to save..."
											/>
										)}
									</>
								)}
							</>
						)}
					</div>
				)}
				{showDivider && <Divider variant="fullWidth" style={{ margin: '8px 0' }} />}
			</div>
			<MaterialFlyOut open={isLookUpOn} onClose={lookUpOff} title="Responses from related projects">
				<LookUp channelId={PCId || undefined} closeDialog={lookUpOff} />
			</MaterialFlyOut>
			<MaterialFlyOut open={isHistoryOn} onClose={historyOff} title="Response changes history">
				<TextHistory channelId={PCId || undefined} />
			</MaterialFlyOut>
		</>
	);
}

export default React.memo(UserInput);
