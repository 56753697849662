import { css } from '@emotion/css/macro';
import Box from '@material-ui/core/Box';
import { Button } from '../../core/components';
import { Contact } from '../types';
import { BasicForm, CheckboxField, TextField } from '../../formaggio';
import { ResponsibilityAreaSelectorConnected } from './ResponsibilityAreaSelector';
import { ContactModel } from '../../core/types/api';
import { ValidationErrors } from '../../formaggio/types';

const baseClass = css`
	margin-top: 1rem;
`;

const submitBtnClass = css`
	margin-right: 0.5rem;
`;

const validate = (values: ContactModel) => {
	const errors: ValidationErrors<ContactModel> = {};
	if (!values.name) {
		errors.name = 'Required field';
	}
	return errors;
};

export type SuccessNewOrUpdatedContactHandler = (arg: Partial<Contact>) => void;

export type ContactFormProps = {
	clientId?: string;
	contact?: Contact;
	onSuccess: SuccessNewOrUpdatedContactHandler;
	projectId?: string;
};

const emptyInitialValues: Partial<Contact> = {
	name: '',
	jobTitle: '',
	organization: '',
	phone: '',
	email: '',
	isISA: false,
};

export default function ContactForm({ clientId, contact, projectId, onSuccess }: ContactFormProps) {
	const isEdit = !!contact?.id;

	if (!clientId && !projectId) return null;

	return (
		<BasicForm
			initialValues={contact || { clientId, projectId, ...emptyInitialValues }}
			className={baseClass}
			validate={validate}
			fetchConfig={{
				query: isEdit ? 'Contact/UpdateContact' : 'Contact/CreateContact',
				method: isEdit ? 'PUT' : 'POST',
				onSuccess,
			}}
		>
			<TextField name="name" label="Name" fullWidth maxLength="250" />
			<TextField name="jobTitle" label="Job Title" fullWidth maxLength="100" />
			<TextField name="organization" label="Organization" fullWidth maxLength="250" />
			<TextField name="phone" label="Phone" type="tel" fullWidth />
			<TextField
				name="email"
				label="E-mail"
				style={{ marginBottom: '0.5rem' }}
				fullWidth
				maxLength="100"
				type="email"
			/>
			<TextField name="siteUrl" label="URL" fullWidth maxLength="250" />
			<ResponsibilityAreaSelectorConnected name="responsibilityArea" />
			<Box mt={1}>
				<CheckboxField name="isISA" label="Is ISA" />
				<CheckboxField name="isPrimary" label="Is Primary" />
			</Box>
			<Box mt={1}>
				<Button color="primary" type="submit" className={submitBtnClass}>
					Submit
				</Button>
			</Box>
		</BasicForm>
	);
}
