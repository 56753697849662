import { get } from 'lodash';
import { css, cx } from '@emotion/css/macro';
import { getQS, hasFlag } from '../core/helpers';
import { Channel, CustomSaq, Workflow, ReportType } from '../core/types/api';
import { RocItem } from './types';
import { RocDataType, RocItemType } from './enums';
import { extractPaymentChannelIds } from './rocItems/TableCell';
import { ReportOrigin, rocItemInputs } from './constants';
import { columnStyles } from './constants/presetsNoFragment';
import { relativeCellClass } from './LinkButton';
import { viewStyleInformationToClassName } from './constants/styles';
import { CustomSaqStatus } from '../customSaq/enums';
import { ALL_PAYMENT_CHANNELS } from './UserData';

export enum EmailHighlight {
	Discussion,
	Evidence,
}

export function getItemEmailHighlightQS() {
	let qs = getQS('discuss');
	if (qs) return { id: qs, type: EmailHighlight.Discussion };
	qs = getQS('evidence');
	if (qs) return { id: qs, type: EmailHighlight.Evidence };
	return { id: null, type: null };
}

export function filterSaqPaymentChannels(
	paymentChannels: Channel[] = [],
	availablePCs: string[] = [],
	useDifferentValuesPerPaymentChannels?: boolean,
): Channel[] {
	if (!useDifferentValuesPerPaymentChannels)
		return paymentChannels
			.filter(({ id }: Channel) => availablePCs?.indexOf(id) >= 0)
			.map((c) => ({ id: c.id, name: c.name }));

	return paymentChannels.filter(({ id, saq }: Channel) => {
		const { basedOn = 0, customSaqId } = saq || {};
		return !availablePCs || availablePCs.indexOf(id) >= 0 || (!basedOn && !customSaqId);
	});
}

export function hasMultipleResponses(multipleResponses?: boolean, rocDataType?: RocDataType) {
	return (
		multipleResponses ||
		rocDataType === RocDataType.SampleSet ||
		rocDataType === RocDataType.MediaFile
	);
}

function getCustomSaqSummaries(id: string, customSaqList: CustomSaq[] = []) {
	return customSaqList.filter(({ id: sid }) => sid === id)[0].summaryIds || [];
}

type GetListsType = Partial<RocItem> & {
	workflows: Workflow[];
	paymentChannels: Channel[];
	customSaqList: CustomSaq[];
	isSummary: boolean;
};

export function getLists({
	workflows = [],
	itemId = '',
	paymentChannels = [],
	saq: inputSaq,
	data = [],
	customSaqList = [],
	summaryId = '',
	isSummary,
}: GetListsType) {
	const dataChannelIds = extractPaymentChannelIds(data);
	const states = dataChannelIds.map((cid) => ({
		cid,
		status: CustomSaqStatus.Applicable,
	}));

	// If has all channels response
	if (dataChannelIds.filter((ci) => !ci || ci === ALL_PAYMENT_CHANNELS).length > 0) {
		states.push(...paymentChannels.map((c) => ({ cid: c.id, status: CustomSaqStatus.Applicable })));
	} else {
		states.push(
			...paymentChannels.map(({ id: cid, saq }) => {
				const { basedOn, customSaqId } = saq || {};

				if (customSaqId) {
					const customSaqItemState = getCustomSaqSummaries(customSaqId, customSaqList).filter(
						({ summaryId: saqSumId }) => saqSumId === summaryId || saqSumId === itemId,
					)[0];

					if (!customSaqItemState) return { cid, status: CustomSaqStatus.NotApplicable };

					return { cid, status: customSaqItemState.status } as any;
				}

				return {
					cid,
					status:
						inputSaq === undefined || !basedOn || hasFlag(inputSaq, basedOn)
							? CustomSaqStatus.Applicable
							: CustomSaqStatus.NotApplicable,
				};
			}),
		);
	}

	return {
		workflowsList: workflows.filter(({ itemId: iid }) => iid === itemId) || [],
		channelStates: states,
		hasSaqValue: isSummary || !!summaryId || inputSaq !== undefined,
	};
}

export function isInputItem(type?: RocItemType) {
	return rocItemInputs.indexOf(type || 0) >= 0;
}

const MAX_REQUIREMENT_NUMBER = 12;

export function requirementNumberToString(requirement?: number) {
	if (!requirement) return '';
	if (requirement <= MAX_REQUIREMENT_NUMBER) return `R${requirement}`;
	return `A${requirement - MAX_REQUIREMENT_NUMBER}`;
}

export function getReportCellProps(props: any = {}, idx: number, rowSubType?: string) {
	const oldStyle = props.style;
	const columnStyle = { ...oldStyle, ...get(columnStyles, `${rowSubType}[${idx}]`, {}) };

	return {
		className: cx(
			relativeCellClass,
			viewStyleInformationToClassName(columnStyle),
			columnStyle?.attributes?.style &&
				css`
					${columnStyle.attributes.style}
				`,
		),
		attributes: { ...oldStyle?.attributes, ...columnStyle?.attributes },
	};
}

export function getReportOrigin(version: ReportType): ReportOrigin {
	if (version === ReportType.FedRAMP_rev5) return ReportOrigin.FedRAMP;

	return ReportOrigin.PCI_DSS;
}

export function isPciDssVersion(version: ReportType) {
	return getReportOrigin(version) === ReportOrigin.PCI_DSS;
}

export function isFedRampVersion(version: ReportType) {
	return getReportOrigin(version) === ReportOrigin.FedRAMP;
}

export function isScopeVersion(version: ReportType) {
	return version === ReportType.PCI_DSS_Scope_Builder;
}
